<template>
  <div>
    <!--  we have many different types of rooms ... please click proper tab -->
    <display-page-summary :mysummary="mysummary"></display-page-summary>

    <!-- accordion here -->
    <accordion style="margin-top:-10px">
        <accordion-item v-for="(item, index) in myaccordion" :key="index">

          <!-- This slot will handle the title/header of the accordion and is the part you click on -->
          <template slot="accordion-trigger">
            <div class="my_item_title_class">
              {{item.title}} <!-- string -->
            </div>
          </template>

          <!-- This slot will handle all the content that is passed to the accordion -->
          <template slot="accordion-content">
            <display-tab-content :content="item.content"></display-tab-content>
           <!-- {{item.content}} --> <!-- array of { title: "hello  ", des: "hello }  "-->
            <photo-gal  :showThumbnails="mythumnails" :tabphotos="myphotos[index]"/>   <!-- photo   array of array of photos. -->
          </template>

        </accordion-item>
    </accordion>

  </div>
</template>

<script>
  export default {
    name: 'BaseInterface',
    components: {
      PhotoGal: () => import('@/components/photo-gallery/PhotoGal.vue'),
      accordion: () => import('@/components/accordion/accordion.vue'),
      AccordionItem: () => import('@/components/accordion/accordion-item.vue'),
      DisplayPageSummary: () => import('@/components/home/interface/DisplayPageSummary.vue'),
      DisplayTabContent: () => import('@/components/home/interface/DisplayTabContent.vue')
    },
    props: [ 'pagename', 'mysummary', 'myaccordion', 'myphotos' ],
    computed: {
      mythumnails() {
        switch (this.pagename) {
          case 'garden1': return true;
          case 'garden2': return true;
          case 'paper': return true;
          default: return false
        }
      },
    }
  }
</script>

<style scoped>
.my_item_title_class {
  font-size: 16px;
  font-weight: bold;
/*text-shadow: 1px 1px 1px black;  */
}
</style>
